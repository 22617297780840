<template>
	<section>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Fotos de la Actividad #{{ parseInt($route.params.id) }}</div>
			</div>
			<div v-if="cargando" class="card-body">
				<div class="loading loading-lg"></div>
			</div>
			<div v-else class="card-body">
				<div class="carousel">
					<input v-for="(foto, k) in fotos" :key="k" class="carousel-locator" :id="'slide-'+k" type="radio" name="carousel-radio" hidden :checked="k == 0">
					<div class="carousel-container">
						<figure v-for="(foto, k) in fotos" :key="k" class="carousel-item">
							<label class="item-prev btn btn-action btn-lg" :for="'slide-'+(k == 0 ? cantidad-1 : k-1)"><i class="icon icon-arrow-left"></i></label>
							<label class="item-next btn btn-action btn-lg" :for="'slide-'+(k == cantidad-1 ? 0 : k+1)"><i class="icon icon-arrow-right"></i></label>
							<img class="img-responsive" :src="foto" :alt="'Foto '+(k+1)">
						</figure>
					</div>
					<div class="carousel-nav">
						<label v-for="(foto, k) in fotos" :key="k" class="nav-item text-hide c-hand" :for="'slide-'+k">{{ k+1 }}</label>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Fotos',
		data() {
			return {
				cargando: false,
				fotos: [],
				cantidad: 0
			}
		},
		mounted() {
			document.title = `Fotos de la Actividad #${this.$route.params.id}`;
			this.infoUsuario();
			this.cargarDatos();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			cargarDatos() {
				this.cargando = true;
				this.$store.state.api
					.get('ordenes/ver_fotos/' + parseInt(this.$route.params.id))
					.then(res => {
						this.fotos = res.data;
						this.cantidad = res.data.length;
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.cargando = false);
			},
		}
	}
</script>